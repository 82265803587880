@import "~normalize.css";
@import '~antd/dist/antd.css';

#root {
    min-height: 100%;
    display: flex;
}

.ResultsTable__Dots {
    bottom: -25px !important;
    align-items: center;
}
.ResultsTable__Dots::before {
    content: 'Other options:';
    display: block;
    margin-right: 10px;
}
.ResultsTable__Dots li {
    height: auto !important;
}
.ResultsTable__Dots li button {
    height: auto !important;
    background: white !important;
    font-size: 14px !important;
    color: black !important;
}

.site-result-demo-error-icon {
  color: red;
}